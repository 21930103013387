import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import "./App.css";
import cv from "./MyCV_Abdenour.pdf";

function Home() {
  return (
    <div>
      <section id="home" class="fullheight px-lg-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-10">
              <h1
                style={{ lineHeight: "4rem" }}
                class="display-4 fw-bold"
                data-aos="fade-up"
              >
                Hello, I'm Abdenour Zermat, a passionate{" "}
                <span class="text-brand">DEVELOPER</span> from Algeria
              </h1>
              <p
                style={{ lineHeight: "2rem" }}
                class="lead mt-2 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Hi, I'm Abdenour Zermat, a Computer Science student passionate
                about web development, graphics programming and AI, creating
                immersive and engaging digital experiences.
              </p>
              <div
                className="d-flex align-items-center"
                data-aos="fade-up "
                data-aos-delay="300"
              >
                <a
                  href={cv}
                  className="btn btn-yellow me-3"
                  download="Abdenour_Zermat_CV.pdf"
                >
                  Download My CV
                </a>
                <a
                  href="mailto:zermatabdenour@gmail.com"
                  class="underline-animation"
                >
                  email: zermatabdenour@gmail.com
                </a>
              </div>

              <div class="social-icons mt-5 ">
                <a
                  href="https://www.linkedin.com/in/abdennour-zermat-1aab05258/"
                  className="mx-1 bg-base"
                >
                  {<LinkedInIcon />}
                </a>
                <a
                  href="mailto:zermatabdenour@gmail.com"
                  className="mx-1 bg-base"
                >
                  {<MailIcon />}{" "}
                </a>
                <a
                  href="https://github.com/ZermatAbdenour"
                  className="mx-1 bg-base"
                >
                  {" "}
                  {<GitHubIcon />}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
