import React from "react";
import clogo from "./img/TeckStack/C_Logo.png";
import unitylogo from "./img/TeckStack/Unity-Pro-logo.png.webp";
function Techstack() {
  return (
    <section id="techstack" className="fullheight px-lg-5">
      <div className="container">
        <div className="row pb-4" data-aos="fade-up">
          <div className="col-lg-8">
            <h6 className="text-brand">Techstack</h6>
            <h1>I use these Technologies to develop my applications</h1>
          </div>
        </div>
        <div className="tech-stack d-flex flex-wrap justify-content-center gap-3">
          {[
            { name: "C", src: clogo },
            { name: "C++", src: "https://www.svgrepo.com/show/452183/cpp.svg" },
            {
              name: "C#",
              src: "https://www.svgrepo.com/show/452184/csharp.svg",
            },
            {
              name: "Java",
              src: "https://www.svgrepo.com/show/452234/java.svg",
            },
            {
              name: "Python",
              src: "https://www.svgrepo.com/show/452091/python.svg",
            },
            {
              name: "Node.js",
              src: "https://www.svgrepo.com/show/452075/node-js.svg",
            },
            {
              name: "Express.js",
              src: "https://www.svgrepo.com/show/330398/express.svg",
            },
            {
              name: ".NET",
              src: "https://www.svgrepo.com/show/508910/dotnet.svg",
            },
            {
              name: "FastAPI",
              src: "https://www.svgrepo.com/show/330413/fastapi.svg",
            },
            {
              name: "HTML",
              src: "https://www.svgrepo.com/show/349402/html5.svg",
            },
            {
              name: "CSS",
              src: "https://www.svgrepo.com/show/452185/css-3.svg",
            },
            {
              name: "React",
              src: "https://www.svgrepo.com/show/374032/reactjs.svg",
            },
            {
              name: "Android Studio",
              src: "https://www.svgrepo.com/show/424906/logo-google-android-studio.svg",
            },
            {
              name: "Unity",
              src: unitylogo,
            },
            {
              name: "GLSL",
              src: "https://www.svgrepo.com/show/373629/glsl.svg",
            },
            {
              name: "MySQL",
              src: "https://www.svgrepo.com/show/303251/mysql-logo.svg",
            },
            {
              name: "PostgreSQL",
              src: "https://www.svgrepo.com/show/354200/postgresql.svg",
            },
            {
              name: "MongoDB",
              src: "https://www.svgrepo.com/show/331488/mongodb.svg",
            },
            {
              name: "GitHub",
              src: "https://www.svgrepo.com/show/512317/github-142.svg",
            },
            {
              name: "Git",
              src: "https://www.svgrepo.com/show/452210/git.svg",
            },
            {
              name: "Postman",
              src: "https://www.svgrepo.com/show/354202/postman-icon.svg",
            },
          ].map((tech, index) => (
            <div
              key={index}
              className="tech-item d-flex align-items-center rounded shadow-sm p-2"
              style={{ background: "var(--color-techStackBG)", width: "190px" }}
              data-aos="fade-up"
            >
              <img
                className="tech-icon rounded me-3"
                src={tech.src}
                alt={`${tech.name} logo`}
                style={{ width: "40px", height: "40px", objectFit: "contain" }}
              />
              <span className="tech-name">{tech.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Techstack;
