import React from "react";
import "./App.css";
import ME from "./img/abdenour.jpg";
import { Button, Avatar } from "@mui/material";

function Navbar() {
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <div class="container flex-lg-column">
          <a class="navbar-brand mb-lg-5 mx-lg-auto" href="#">
            <span className="h3 d-flex align-itmes-center fw-bold d-block d-lg-none ">
              {" "}
              <Avatar className="mx-4" src={ME} />
            </span>

            <img
              className="d-none d-lg-block rounded-circle"
              src={ME}
              loading="lazy"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto flex-lg-column text-lg-center">
              <li class="nav-item">
                <a class="nav-link " aria-current="page" href="#home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#work">
                  Work
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">
                  About
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#techstack">
                  TechStack
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
