import React from 'react'
import './App.css'
import { DarkMode } from './DarkMode/DarkMode';

function Darkmode() {
  return (
    <section className='px-lg-5 dark-mode'>
        <div className='container'>
        <div >          <DarkMode /> 

</div>
            </div> 
            </section>
  )
}

export default Darkmode