import React from 'react'
import Home from './home'
import './App.css';
import Services from './services'
import Work from './work';
import About from './About';
import Techstack from './Techstack';
import Contact from './Contact';
import Footer from './Footer';
import Darkmode from './darkmode';


function Contentwrapper() {
  return (
    <div id='allcontent'>
         <Darkmode/>
        <Home/>
        <Services/>
        <Work/>
        <About/>
        <Techstack/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Contentwrapper