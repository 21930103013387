import 'aos/dist/aos.css'; // Import the CSS file for AOS styles
import AOS from 'aos';

import { useEffect } from 'react';
import './App.css';
import Contentwrapper from './Content-wrapper';
import Home from './home';
import Navbar from './navbar';

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div className="App  " data-bs-spy="scroll" data-bs-target=".navbar">
      <Navbar/>
      <Contentwrapper/>
    </div>
  );
}

export default App;
