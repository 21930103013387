import React from "react";
import "./App.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const notify = () => toast("Email Sent Successfully ");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "PortfolioContact",
        "template_8klkowb",
        form.current,
        "JZfiN9YdOXhnGb-t6"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contact" class=" fullheight px-lg-5">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-8 pb-4" data-aos="fade-up">
            <h6 class="text-brand">CONTACT</h6>
            <h1>Interested in working together? Let's talk</h1>
          </div>

          <div class="col-lg-8" data-aos="fade-up" data-aos-delay="300">
            <form
              action="#"
              class="row g-lg-3 gy-3"
              ref={form}
              onSubmit={sendEmail}
            >
              <div class="form-group col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="user_name"
                  placeholder="Enter your name"
                />
              </div>
              <div class="form-group col-md-6">
                <input
                  type="email"
                  class="form-control"
                  name="user_email"
                  placeholder="Enter your email"
                />
              </div>
              <div class="form-group col-12">
                <input
                  type="text"
                  class="form-control"
                  name="subject_name"
                  placeholder="Enter subject"
                />
              </div>
              <div class="form-group col-12">
                <textarea
                  rows="4"
                  class="form-control"
                  name="message"
                  placeholder="Enter your message"
                ></textarea>
              </div>
              <div class="form-group col-12 d-grid">
                <button
                  type="submit"
                  onClick={notify}
                  value="Send"
                  class="btn btn-yellow"
                >
                  Contact me
                </button>

                <ToastContainer
                  position="bottom-center"
                  autoClose={4000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
