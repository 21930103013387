import React from "react";
import "react-vertical-timeline-component/style.min.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import SchoolIcon from "@mui/icons-material/School";
import ComputerIcon from "@mui/icons-material/Computer";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import "./App.css";
function About() {
  return (
    <section id="about" className="fullheight px-lg-5">
      <div className="container">
        <div class="row pb-4" data-aos="fade-up">
          <div class="col-lg-8">
            <h6 class="text-brand">About Me </h6>
            <h1>Studies and University Journey</h1>
          </div>
        </div>

        <VerticalTimeline lineColor={"var(--color-base)"}>
          <VerticalTimelineElement
            contentStyle={{
              backgroundColor: "var(--color-base)",
              color: "var(--color-bodydark)",
              border: "0px",
            }}
            className="vertical-timeline-element--work"
            icon={<SchoolIcon />}
            iconStyle={{
              background: "var(--color-base)",
              color: "var(--color-bodydark)",
            }}
            contentArrowStyle={{ visibility: "hidden" }}
          >
            <h3 className="vertical-timeline-element-title bg-base">
              Baccalaureate Degree
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ color: "gray", fontSize: "1rem" }}
            >
              2019 - 2021
            </h4>
            <p>
              I obtained my Baccalaureate degree in Mathematical Techniques and
              Electrical Engineering.
            </p>
            <h3 style={{ color: "gray", fontSize: "1rem", margin: "1em 0em" }}>
              Grade : 17.67/20
            </h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{
              backgroundColor: "var(--color-base)",
              color: "var(--color-bodydark)",
              border: "0px",
            }}
            className="vertical-timeline-element--work"
            icon={<ComputerIcon />}
            iconStyle={{
              background: "var(--color-base)",
              color: "var(--color-bodydark)",
            }}
            contentArrowStyle={{ visibility: "hidden" }}
          >
            <h3 className="vertical-timeline-element-title bg-base">
              Computer Science Student
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ color: "gray", fontSize: "1rem" }}
            >
              2021-2024
            </h4>
            <p>
              I am pursuing a degree in Computer Science. I completed 2 years of
              general computer science classes, and I am currently specializing
              in Artificial Intelligence and Data Science.
            </p>
            <h3 style={{ color: "gray", fontSize: "1rem", margin: "1em 0em" }}>
              Higher School of Computer Science and Digital Technologies
              (Estin).
            </h3>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default About;
