import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import "./App.css";
export default function Footer() {
  return (
    <footer class="footer m-auto p-auto">
      <ul class="social-icon m-auto ">
        <li class="social-icon__item">
          <a class="social-icon__link" href="https://github.com/ZermatAbdenour">
            <GitHubIcon />
          </a>
        </li>
        <li class="social-icon__item">
          <a class="social-icon__link" href="mailto:zermatabdenour@gmail.com">
            <MailIcon />
          </a>
        </li>
        <li class="social-icon__item">
          <a
            class="social-icon__link"
            href="https://www.linkedin.com/in/abdennour-zermat-1aab05258/"
          >
            <LinkedInIcon />
          </a>
        </li>
      </ul>

      <p className="text-center">
        &copy;2024 Abdenour Zermat| All Rights Reserved
      </p>
    </footer>
  );
}
