import React from "react";
import CodeIcon from "@mui/icons-material/Code";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import PaletteIcon from "@mui/icons-material/Palette";
import DevicesIcon from "@mui/icons-material/Devices";
import GitHubIcon from "@mui/icons-material/GitHub";
import GameIcon from "@mui/icons-material/Games";
import DataIcon from "@mui/icons-material/Dataset";
import "./App.css";
function Services() {
  return (
    <section id="services" class="fullheight px-lg-5">
      <div class="container">
        <div class="row pb-4" data-aos="fade-up">
          <div class="col-lg-8">
            <h6 class="text-brand">SERVICES</h6>
            <h1>Services That I Provide</h1>
          </div>
        </div>

        <div class="row gy-4">
          <div class="col-lg-4 col-md-6" data-aos="fade-up">
            <div class="service p-4 bg-base rounded-4 shadow-effect">
              <div class="iconbox rounded-4">
                <CodeIcon />
              </div>
              <h5 class="mt-4 mb-2 bg-base">Backend-End Development</h5>
              <p>
                I specialize in backend development with .NET and Express.js,
                building efficient and scalable APIs and services.
              </p>
              <a href="#" class="underline-animation bg-base">
                Read More
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up">
            <div class="service p-4 bg-base rounded-4 shadow-effect">
              <div class="iconbox rounded-4">
                <GameIcon />
              </div>
              <h5 class="mt-4 mb-2 bg-base">Game Development</h5>
              <p>
                I work on game development with Unity and graphics programming
                using OpenGL, focusing on performance and immersion.
              </p>
              <a href="#" class="underline-animation bg-base">
                Read More
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up">
            <div class="service p-4 bg-base rounded-4 shadow-effect">
              <div class="iconbox rounded-4">
                <DataIcon />
              </div>
              <h5 class="mt-4 mb-2 bg-base">Data Analysis</h5>
              <p>
                {" "}
                I work on data analysis in Python, focusing on extracting
                insights and visualizing data effectively.
              </p>
              <a href="#" class="underline-animation bg-base">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
