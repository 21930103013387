import React from "react";
import "./App.css";
import AetherScreeShot from "./img/AetherRenderer.jpg";
import GitHubIcon from "@mui/icons-material/GitHub";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import vizar1 from "./img/Vizar/1.jpg";
import vizar2 from "./img/Vizar/4.jpg";
import vizar3 from "./img/Vizar/AR1.jpg";
import vizar4 from "./img/Vizar/AR3.jpg";
import buildFlow from "./img/BuildFlow.png";
import baridiTrust from "./img/BaridiTrust.jpg";
import trytiesReturn from "./img/TrytiesReturn.jpg";
import dzmohami from "./img/dzmohami.jpg";
function Work() {
  return (
    <section id="work" class="fullheight px-lg-5">
      <div class="container">
        <div class="row pb-4" data-aos="fade-up">
          <div class="col-lg-8">
            <h6 class="text-brand">WORK</h6>
            <h1>My Projects</h1>
          </div>
        </div>

        <div class="row gy-4">
          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4 ratio ratio-16x9">
                <img
                  class="rounded-4"
                  src={baridiTrust}
                  alt=""
                  style={{ objectFit: "fill" }}
                  loading="lazy"
                />
              </div>
              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">Express, React</p>
                  <h4 class="bg-base">BaridiTrust</h4>
                  <p>
                    BaridiTrust provides a secure payment solution for
                    e-commerce websites integrated with Algérie Poste. It
                    ensures buyer-seller trust by holding the buyer's payment in
                    escrow until both parties confirm the transaction's
                    completion.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://github.com/ZermatAbdenour/Innov-Post"
                    class="ms-auto bg-base"
                  >
                    {<GitHubIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4 ratio ratio-16x9">
                <img
                  class="rounded-4"
                  src={AetherScreeShot}
                  alt=""
                  style={{ objectFit: "contain" }}
                  loading="lazy"
                />
              </div>
              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">OpenGL, C++</p>
                  <h4 class="bg-base">Aether Renderer</h4>
                  <p>
                    Aether Renderer is a physically-based rendering (PBR) engine
                    built with OpenGL and C++. It is designed for real-time
                    rendering with advanced lighting models and post-processing
                    effects.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://github.com/ZermatAbdenour/Aether-Renderer"
                    class="ms-auto bg-base"
                  >
                    {<GitHubIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4">
                <div id="carouselVizar" class="carousel slide">
                  <div class="carousel-inner">
                    <div class="carousel-item active ratio ratio-16x9">
                      <img
                        class="d-block rounded-4 img-fluid"
                        src={vizar1}
                        alt=""
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div class="carousel-item ratio ratio-16x9">
                      <img
                        class="d-block rounded-4 img-fluid"
                        src={vizar2}
                        alt=""
                        style={{ objectFit: "contain" }}
                        loading="lazy"
                      />
                    </div>
                    <div class="carousel-item ratio ratio-16x9">
                      <img
                        class="d-block rounded-4 img-fluid"
                        src={vizar3}
                        alt=""
                        style={{ objectFit: "contain" }}
                        loading="lazy"
                      />
                    </div>
                    <div class="carousel-item ratio ratio-16x9">
                      <img
                        class="d-block rounded-4 img-fluid"
                        src={vizar4}
                        alt=""
                        style={{ objectFit: "contain" }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselVizar"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselVizar"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">DotNet, Android Studio, Unity</p>
                  <h4 class="bg-base">Vizar</h4>
                  <p>
                    VizAr is a mobile app that allows users to visualize
                    furniture in their real-world environments using augmented
                    reality (AR). Users can place virtual furniture in their
                    spaces to see how it looks and fits before making a
                    purchase.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://github.com/ZermatAbdenour/VizarProject"
                    class="ms-auto bg-base"
                  >
                    {<GitHubIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4 ratio ratio-16x9">
                <img
                  class="rounded-4"
                  src={buildFlow}
                  alt=""
                  style={{ objectFit: "contain" }}
                  loading="lazy"
                />
              </div>
              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">Express, React</p>
                  <h4 class="bg-base">BuildFlow</h4>
                  <p>
                    BuildFlow is an AI-powered app that uses the OpenAI API to
                    automate task assignment, optimize workflows, and streamline
                    task verification for real-world projects.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://github.com/your-repository"
                    class="ms-auto bg-base"
                  >
                    {<GitHubIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4 ratio ratio-16x9">
                <img
                  class="rounded-4"
                  src={trytiesReturn}
                  alt=""
                  style={{ objectFit: "contain" }}
                  loading="lazy"
                />
              </div>
              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">Unity, C#</p>
                  <h4 class="bg-base">TrytiesReturn</h4>
                  <p>
                    Tryties Return is an exhilarating mobile zombie shooter game
                    with captivating single-player gameplay, and
                    soon-to-be-added multiplayer mode for thrilling co-op and
                    PvP battles.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://play.google.com/store/search?q=tryties%20return&c=apps"
                    class="ms-auto bg-base"
                  >
                    {<PlayArrowIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" data-aos="fade-up">
            <div class="card-custom rounded-4 bg-base shadow-effect">
              <div class="card-custom-image rounded-4 ratio ratio-16x9">
                <img
                  class="rounded-4 "
                  src={dzmohami}
                  alt=""
                  style={{ objectFit: "fill" }}
                  loading="lazy"
                />
              </div>
              <div class="card-custom-content p-4 d-flex flex-column justify-content-between">
                <div>
                  <p class="mb-4">FastAPI, React</p>
                  <h4 class="bg-base">DZmohami</h4>
                  <p>
                    Tryties Return is an exhilarating mobile zombie shooter game
                    with captivating single-player gameplay, and
                    soon-to-be-added multiplayer mode for thrilling co-op and
                    PvP battles.
                  </p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    href="https://github.com/AhmedGsa/GL-Project"
                    class="ms-auto bg-base"
                  >
                    {<GitHubIcon style={{ fontSize: "2rem" }} />}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
